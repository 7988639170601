.mainSec {
    border-bottom: 2px solid #1b9fbd;
}

.stockImg {
    border: 2px solid white;
    max-width: 100%;
}

.mainSec h2 {
    margin-top: 50px;
    font-size: 65px;
    font-weight: bold;
    padding-bottom: 15px;
    text-align: center;
}

.mainSec h4 {
    color: #1b9fbd;
    font-size: 20px;
    font-weight: lighter;
    margin: 60px 0 0 0;
}

.par1,
.par2 {
    text-align: left;
    font-size: 25px;
    line-height: 50px;
    FONT-WEIGHT: 100;
    margin-bottom: 30px;
    margin-top: 45px;
}

.par2 {
    text-align: right;
}

.par3 {
    font-size: 40px;
    line-height: 45px;
    text-align: center;
    FONT-WEIGHT: 100;
    margin: 70px 0 70px 0;
    padding-bottom: 70px;
    border-bottom: 2px solid white;
}

.row2,
.row3 {
    border-bottom: 2px solid white;
    padding: 40px 0 40px 0;
}


.teamTxt h2 {
    font-weight: bold;
    text-align: center;
    font-size: 50px;
}

.teamTxt p {
    font-weight: 100;
    text-align: center;
    font-size: 22px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 50px;
}

.allMembersDiv {
    display: flex;
    justify-content: center;
}

.teamMember {
    text-align: center;
    margin: 25px;
}

.teamMember img {
    border: 2px solid white;
    border-radius: 50%;
    height: 230px;
}

.teamMember h5 {
    font-weight: 100;
    color: #1b9fbd;
    font-size: 20px;
    margin-top: 0;
}

.teamMember h4 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
}

.memberUl {
    padding: 0;
    margin: 0 auto;
}

.teamMember ul li {
    list-style-type: none;
    font-weight: 100;
    margin: 10px;
}

.hiddenDiv {
    display: none;
}

.visibleDiv {
    border: 2px solid white;
    border-radius: 5px;
    padding: 15px;
}

#memberBtn1,
#memberBtn2,
#memberBtn3 {
    padding: 10px;
    margin: 10px;
    font-size: 17px;
    border-radius: 10px;
    background: rgb(60, 60, 60);
    border: 1px solid white;
    color: white;
}

#memberBtn1:hover,
#memberBtn2:hover,
#memberBtn3:hover {
    transition: all .5s ease-out;
    background: rgb(60, 60, 60);
    border: 1px solid #1b9fbd;
    color: #1b9fbd;
}

@media (max-width: 1199px) {

    .row1,
    .row2,
    .row3 {
        text-align: center;
    }

    .row2,
    .row3 {
        border-bottom: 2px solid white
    }

    .par1,
    .par2,
    .par3 {
        text-align: center;
        margin: 15px 15px;
    }

    .bottomImg {
        display: none;
    }

    .memberUl {
        padding: 0;
        width: 50%;
        margin: 0 auto;
    }

    .teamMember img {
        border: 2px solid white;
        border-radius: 50%;
        height: 230px;
    }
}

@media (min-width: 1199px) {
    .teamMember img {
        width: 60%;
    }
}