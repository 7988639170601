.header h1 {
    text-align: center;
    border-bottom: 2px solid white;
    width: 40%;
    margin: 0 auto;
    margin-top: 50px;
    padding: 10px;
    margin-bottom: 30px;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 55px;
}


.techContainer h4 {
    text-align: center;
    width: 65%;
    margin: 0 auto;
    letter-spacing: 1px;
    line-height: 40px;
    margin-bottom: 25px;
    margin-top: 25px;
}

.row {
    display: flex;
    justify-content: center;
    padding-left: 0;
}


.description li,
.description p {
    list-style-type: none;
    position: relative;
    right: 15px;
    font-size: 20px;
    line-height: 33px;
}


.box {
    text-align: center;
    margin: 25px 50px 25px 50px;
    max-width: 100%;
    border: 5px solid white;
    border-radius: 17px;
    padding: 0;
}

.box h3 {
    background: white;
    color: black;
    padding: 10px;
    font-weight: bold;
}

.box img {
    margin: 0 auto;
    width: 100%;
    height: 300px;
    border-radius: 10px;
}

.modalButton {
    width: 30%;
    margin: 40px auto;
    padding: 12px;
    font-size: 20px;
    border-radius: 10px;
    background: rgb(60, 60, 60);
    border: 1px solid white;
    color: white;
}

.modalButton:hover {
    transition: all .25s ease-out;
    background: rgb(60, 60, 60);
    border: 1px solid #1b9fbd;
    color: #1b9fbd;
}

.visibotPicture {
    width: 100%;
    display: flex;
}

.techSlideCont {
    position: relative;
    text-align: center;
    color: white;
}

.techSlideImg {
    opacity: .5;
    filter: brightness(110%);
    border: 3px solid white;
    border-radius: 10px;
    width: 100%;
}

.techSlideTxt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 35px;
    width: 50%;
    border: 2px solid white;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 1400px) {
    .header h1 {
        width: 70%;
    }
}


@media (max-width: 576px) {
    .box {
        width: 75%;
    }

    .header {
        width: 100%;
    }

    .techContainer h4 {
        width: 80%;
    }

    .header h1 {
        width: 90%;
        text-align: center;
    }

    .techSlideTxt {
        width: 75%;
        max-height: 95%;
        font-size: 15px;
    }
}