html,
body {
    padding: 0;
    margin: 0;
    --bs-bg-opacity: 1;
    background-color: black;
    color: white;
}

a {
    color: white;
}

a:hover {
    color: white;
}

.App {
    font-family: 'Segoe UI', 'san-serif';
}