.imgContainer {
    position: relative;
}

.imgContainer img {
    width: 100%;
    border-bottom: 2px solid white;
}

.mainText {
    position: absolute;
    top: 30%;
    right: 10%;
    z-index: 2;
    background: rgba(21, 21, 21, 0.75);
    padding: 50px;
    border-radius: 70px;
    text-align: center;
    border: 2px solid white;
}

.futureTxt {
    display: block;
    font-weight: bold;
}

.mainText h2 {
    color: white;
    font-size: 40px;
}

.mainTextBtn {
    padding: 12px;
    font-size: 20px;
    border-radius: 10px;
    background: rgb(60, 60, 60);
    border: 1px solid white;
    color: white;
}

.mainTextBtn:hover {
    background: rgb(60, 60, 60);
    border: 1px solid #1b9fbd;
    color: #1b9fbd;
}

.mainText h2,
.mainTextBtn {
    animation: fadeInAnimation ease .75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.subTxt {
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 410px;
}

.subTxt h2 {
    font-size: 50px;
    font-weight: bold;
    border-bottom: 2px solid white;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 25px;
    width: 35%;
}

.subTxt h4 {
    width: 55%;
    margin: 0 auto;
    font-size: 27px;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@media (max-width: 1300px) {
    .mainText {
        padding: 20px;
        width: 60%;
        right: 20%;
        top: 10%;
        height: 250px;
    }

    .futureTxt {
        display: block;
        font-weight: bold;
    }

    .mainText h2 {
        color: white;
        font-size: 45px;
    }

    .mainTextBtn {
        padding: 15px;
        width: 140px;
        font-size: 17px;
    }
}

@media (max-width: 992px) {
    .mainText {
        padding: 15px;
        width: 60%;
        right: 20%;
        top: 5%;
        height: 200px;
    }

    .futureTxt {
        display: block;
        font-weight: bold;
    }

    .mainText h2 {
        color: white;
        font-size: 35px;
    }

    .mainTextBtn {
        padding: 10px;
        width: 125px;
        font-size: 15px;
    }

    .subTxt {
        height: 350px;
    }

    .subTxt h2 {
        font-size: 40px;
        font-weight: bold;
        border-bottom: 2px solid white;
        margin: 0 auto;
        margin-bottom: 50px;
        padding: 25px;
        width: 60%;
    }

    .subTxt h4 {
        width: 70%;
        margin: 0 auto;
        font-size: 20px;
    }
}

@media (max-width: 600px) {

    .imgContainer img {
        height: 240px;
    }

    .mainText {
        padding: 20px;
        width: 60%;
        right: 20%;
        top: 10%;
        height: 160px;
    }

    .futureTxt {
        display: block;
        font-weight: bold;
    }

    .mainText h2 {
        color: white;
        font-size: 23px;
    }

    .mainTextBtn {
        padding: 7px;
        width: 125px;
        font-size: 15px;
    }

    .subTxt h2 {
        font-size: 30px;
        font-weight: bold;
        border-bottom: 2px solid white;
        margin: 0 auto;
        margin-bottom: 40px;
        padding: 20px;
        width: 90%;
    }

    .subTxt h4 {
        width: 95%;
        margin: 0 auto;
        font-size: 19px;
    }
}

@media (max-width: 510px) {
    .mainText {
        padding: 20px;
        width: 65%;
        right: 17%;
        height: 185px;
    }

    .futureTxt {
        font-size: 30px;
        display: block;
        font-weight: bold;
    }

    .mainText h2 {
        color: white;
        font-size: 23px;
    }

    .mainTextBtn {
        padding: 5px;
        width: 130px;
        height: 40px;
        font-size: 17px;
    }
}