.imgWrap {
    width: 40%;
}

.navimg {
    width: 60%;
    margin: 10px;
}

.navbar-toggler {
    background: #000000;
}

.navbar-toggler-icon {
    filter: invert(1);
}

.navFull {
    border-bottom: 2px solid lightgray;
    padding-bottom: 15px;
}

.navWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeIcon {
    font-size: 22px;
    margin-right: 3px;
}

.navItem {
    font-size: 22px;
    color: white;
    width: 160px;
    text-align: center;
    margin: 10px;
}

.navItem:hover {
    transition: all .25s ease-out;
    color: #1b9fbd;
    border-bottom: 1px solid #1b9fbd;
}

.socials {
    color: #c0bfbf;
    font-size: 25px;
    margin: 0 10px 0 10px;
}

.socials:hover {
    transition: all .5s ease-out;
    color: #1b9fbd;
}


@media (max-width: 1300px) {

    .navWrap {
        justify-content: right;
    }

    .navItem {
        width: 115px;
        margin: 6px;
        font-size: 17px;
    }

    .imgWrap {
        width: 38%;
    }
}

@media (max-width: 991px) {

    .imgWrap {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .navimg {
        left: 0;
        width: 35%;
        justify-content: center;
        padding: 10px;
    }

    .navItem {
        margin: 10px;
        font-size: 20px;
        width: 100%;
    }

    .socials {
        margin: 5px 10px 0 10px;
    }
}

@media (max-width: 600px) {

    .navimg {
        width: 60%;
        justify-content: center;
    }

    .socials {
        font-size: 25px;
        margin: 0 5px 0 5px;
    }
}

@media (min-width: 992px) {
    .navWrap {
        height: 125px;
    }

    .socials {
        color: #c0bfbf;
        font-size: 25px;
    }
}