.btnContainer {
    margin: 20px;
}

.literatureBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    border: 4px solid white;
    border-radius: 5px;
    background: rgb(60, 60, 60);
    color: white;
    font-size: 40px;
    font-weight: bold;
}

.literatureBtn:hover {
    transition: all .5s ease-out;
    background: rgb(50, 50, 50);
    color: #1b9fbd;
    border-color: #1b9fbd;
}

.literatureH1 {
    font-weight: bold;
    font-size: 55px;
    letter-spacing: 1px;
    text-align: center;
    border-bottom: 2px solid white;
    width: 70%;
    margin: 50px auto 50px auto;
    padding: 30px;
}


@media (max-width: 800px) {
    .literatureH1 {
        width: 100%;
        font-size: 45
        px;
    }
}