.borderBottom {
    margin-bottom: 75px;
    padding-bottom: 75px;
    border-bottom: 2px solid white;
}

.newsWrap h1 {
    text-align: center;
    border-bottom: 2px solid white;
    width: 35%;
    margin: 35px auto 35px auto;
    padding-bottom: 15px;
    font-size: 45px;
    font-weight: bold;
}

.newsWrap h3 {
    text-align: center;
    width: 90%;
    margin: 40px auto;
}

.newsWrap h5 {
    border-bottom: 2px solid;
    width: 20%;
    padding-bottom: 10px;
    margin: 25px 0;
    text-align: left;
    font-size: 25px;
}

.newsWrap p {
    font-size: 20px;
    letter-spacing: 1px;
    padding: 25px 0;
}

.newsFooter {
    margin-top: 45px;
}

.newsFooter p {
    padding: 5px 0;
}

@media (max-width: 700px) {
    .newsWrap h5 {
        width: 90%;
        text-align: center;
        margin: 25px auto;
    }

    .newsWrap p {
        text-align: center;
    }
}